<template>
  <div>
    <router-link to="/">
      <v-icon class="top-0 position-absolute ms-2 mt-2 text-secondary" r>mdi-keyboard-backspace</v-icon>
    </router-link>
    <CurveBackground class="w-100 mb-n25" :fill="primaryFill"/>
    <v-container>
      <h1 class="mb-3 fs-1 display-1 text-white mt-n20">Login</h1>
      <v-form v-model="valid" class="mt-40">
        <v-container>
          <v-row>
            <v-text-field
                v-model="email"
                :rules="emailRules"
                label="Email"
                required
            ></v-text-field>
          </v-row>
          <v-row class="mt-5">
            <v-text-field
                v-model="password"
                :rules="passwordRules"
                label="Password"
                required
            ></v-text-field>
          </v-row>
          <v-row class="mt-10">
            <v-btn block color="primary" :disabled="!valid" @click="login()">
              Login
            </v-btn>
          </v-row>
        </v-container>
      </v-form>
    </v-container>
  </div>
</template>

<script>
import {UserTypes} from "@/models/storage";
import CurveBackground from "@/shared/components/curve-background";

export default {
  name: "Home",
  components: {
    CurveBackground,
  },
  data: () => {
    return {
      valid: false,
      email: "peter@gmail.com",
      password: "password",
      passwordRules: [(v) => !!v || "Password is required"],
      emailRules: [
        (v) => !!v || "Email is required",
        (v) => /.+@.+/.test(v) || "Email must be valid",
      ],
    };
  },
  mounted() {
    if (this.$store.state.auth.loggedIn) {
      this.$router.push({name: "my-bookings"});
    }
  },
  methods: {
    async login() {
      await this.$store.dispatch("auth/login", {
        email: this.email,
        password: this.password,
      });
      this.routeBasedOnRole();
    },
    routeBaseOnRole() {
      switch (this.authUser) {
        case UserTypes.participant:
          return this.$router.replace({name: "participant:my-profile"});
        default:
          return this.$router.replace({name: "participant:my-profile"});
      }
    }
  },
  computed: {
    primaryFill() {
      return this.$vuetify.theme.themes.light.primary;
    },
    authUser() {
      return this.$store.state.auth.user;
    }
  },
};
</script>
