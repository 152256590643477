<template>
  <svg viewBox="0 0 439 180" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M77.2071 141.015C47.6768 130.448 14.0981 161.269 1 178V1H438C434.626 14.9134 419.782 55.2096 387.394 105.087C339.169 151.582 320.713 86.4884 234.98 113.54C208.585 132.561 140.078 164.685 77.2071 141.015Z"
      :fill="fill"
      stroke="white"
    />
  </svg>
</template>
<script>
export default {
  props: ["fill"],
};
</script>